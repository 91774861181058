<template>
  <div class="preview-container d-f f-d a-c" v-cloak>
    <page-nav></page-nav>
    <jump></jump>
    <div class="current-position">
      您当前位置：{{ "教育资源网 >" + currentPosition + ">" + fileName + "> 详情" }}
    </div>
    <div class="preview-title">
      {{ fileName }}
    </div>
    <div class="preview-other d-f a-c">
      <img src="@/assets/image/secondary_details_list_time.png" class="time-img" />
      <span class="time">{{ createTime }}</span>
      <img
        src="@/assets/image/secondary_details_list_download.png"
        class="download-img"
      />
      <span class="download">{{ downloadCount }}次 </span>
      <img src="@/assets/image/secondary_details_list_size.png" class="download-img" />
      <span class="download">{{ fileSize | handleSize }}</span>
      <img src="@/assets/image/secondary_details_list_read.png" class="download-img" />
      <span class="download">{{ readCount }}次</span>
      <img
        src="@/assets/image/secondary_details_list_collection.png"
        class="download-img"
      />
      <span class="download">{{ collectionCount }}次</span>
    </div>
    <div class="preview-content d-f j-b">
      <div class="left" v-if="fileUrl != null">
        <video :src="fileUrl" controls>您的浏览器不支持 video 标签。</video>
      </div>
      <div
        class="left"
        v-else-if="fileUrl == null && imgList != null && imgList.length > 0"
      >
        <div v-for="(item, index) in imgList" :key="index">
          <img :src="item" alt="" />
        </div>
        <div class="finish d-f j-c a-c">
          <img src="@/assets/image/preview_finish.png" />
          <p>预览已结束，使用请先下载哦~</p>
        </div>
      </div>
      <div
        class="left d-f j-c"
        v-else-if="fileUrl == null && imgList == null && currentPosition == '教材'"
      >
        <div class="no-data-container d-f a-c j-c">
          <img src="@/assets/image/preview_zip.png" alt="" />
        </div>
      </div>
      <div class="left d-f j-c" v-else>
        <div class="no-data-container d-f a-c j-c">
          <img src="@/assets/image/preview_nodata.png" alt="" />
        </div>
      </div>
      <div class="right">
        <div class="btn d-f j-b">
          <a-button type="primary" class="upload" @click="upload"> 下载 </a-button>
          <button
            class="collection d-f a-c j-c"
            @click="collection"
            v-if="collectionStatus"
          >
            <img src="@/assets/image/preview_have_collection.png" /> 取消收藏
          </button>
          <button class="collection d-f a-c j-c" @click="collection" v-else>
            <img src="@/assets/image/preview_collection.png" /> 收藏
          </button>
        </div>
        <div class="recommend">
          <div class="title">精品推荐</div>
          <div
            class="item d-f"
            v-for="item in boutiqueList"
            :key="item.id"
            @click="toPreview(item)"
          >
            <img
              src="@/assets/image/boutique_icon_word.png"
              v-if="item.fileTypeName === 'word'"
            />
            <img
              src="@/assets/image/boutique_icon_ppt.png"
              v-else-if="item.fileTypeName === 'ppt'"
            />
            <img
              src="@/assets/image/boutique_icon_pdf.png"
              v-else-if="item.fileTypeName === 'pdf'"
            />
            <img
              src="@/assets/image/boutique_icon_video.png"
              v-else-if="item.fileTypeName === 'video'"
            />
            <img
              src="@/assets/image/boutique_icon_zip.png"
              v-else-if="item.fileTypeName === 'package'"
            />
            <img
              src="@/assets/image/boutique_icon_other.png"
              v-else-if="item.fileTypeName === 'other'"
            />
            <p>{{ item.fileName }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageNav from "@/components/PageNav/index.vue";
import Jump from "@/components/Jump/index.vue";
export default {
  inject: ["reload"],
  components: {
    PageNav,
    Jump,
  },
  data() {
    return {
      currentPosition: "",
      fileName: "",
      filePath: "",
      createTime: "",
      downloadCount: null,
      readCount: null,
      collectionCount: null,
      fileSize: null,
      limit: 5,
      boutiqueList: [],
      pageSize: 4,
      imgList: [],
      fileUrl: null,
      collectionStatus: false,
    };
  },
  filters: {
    handleSize(val) {
      if (val != null) {
        let KB = val / 1024;
        let KBInt = Math.floor(KB);
        if (KBInt > 1024) {
          let MB = KB / 1024;
          let MBInt = Math.floor(MB);
          if (MBInt > 1024) {
            let GB = MB / 1024;
            return GB.toFixed(2) + "G";
          } else {
            return MB.toFixed(2) + "M";
          }
        } else {
          return KB.toFixed(2) + "KB";
        }
      }
    },
  },
  methods: {
    //预览详情
    getPreview() {
      this.$api.getPreview(this.$route.query.id, this.pageSize).then((res) => {
        if (res.code === 200) {
          this.fileName = res.data.fileName;
          this.filePath = res.data.filePath;
          this.createTime = res.data.createTime;
          this.downloadCount = res.data.downloadCount;
          this.readCount = res.data.readCount;
          this.collectionCount = res.data.collectionCount;
          this.fileSize = res.data.fileSize;
          this.imgList = res.data.imgUrls;
          this.fileUrl = res.data.fileUrl;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    //查看收藏状态
    getCollectionStatus() {
      this.$api.getCollectionStatus(this.$route.query.id).then((res) => {
        if (res.code === 200) {
          this.collectionStatus = res.data;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    //推荐列表
    getRecommendList() {
      this.$api.getRecommendList(this.$route.query.id, this.limit).then((res) => {
        if (res.code === 200) {
          this.boutiqueList = res.data;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    //去三级详情
    toPreview(item) {
      this.$router.push({
        path: "/preview",
        query: { navId: this.$route.query.navId, id: item.id },
      });
      this.reload();
    },
    //下载
    upload() {
      //获取Token
      let fileName = this.fileName;
      this.$api.getdownloadUrl({ id: this.$route.query.id }).then((res) => {
        if (res.code === 200) {
          let x = new XMLHttpRequest();
          x.open("GET", res.data, true);
          x.responseType = "blob";
          x.onload = (e) => {
            //会创建一个 DOMString，其中包含一个表示参数中给出的对象的URL。这个 URL 的生命周期和创建它的窗口中的 document 绑定。这个新的URL 对象表示指定的 File 对象或 Blob 对象。
            let href = window.URL.createObjectURL(x.response);
            let link = document.createElement("a");
            link.href = href;
            link.download = fileName;
            link.click();
          };
          x.send();
          this.getPreview();
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    //收藏
    collection() {
      this.$api.changeCollectionStatus(this.$route.query.id).then((res) => {
        if (res.code === 200) {
          this.getCollectionStatus();
          this.getCollectionCount();
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    //改变收藏状态时获取收藏次数
    getCollectionCount() {
      this.$api.getCollectionCount(this.$route.query.id).then((res) => {
        if (res.code === 200) {
          this.collectionCount = res.data;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
  },
  created() {
    this.getPreview();
    this.getRecommendList();
    this.getCollectionStatus();
    let navList = this.$ls.get("navList");
    let obj = navList.find((r) => r.id === Number(this.$route.query.navId));
    this.currentPosition = obj.name;
  },
};
</script>

<style scoped lang="less">
@main-color: #05ae44;
[v-cloak] {
  display: none;
}
.preview-container {
  width: 100%;
  min-height: 100vh;
  background: #f1f3f5;
  .current-position {
    width: 100%;
    padding: 0 120px;
    box-sizing: border-box;
    margin-top: 24px;
    font-size: 12px;
    font-weight: 400;
    color: #666666;
  }
  .preview-title {
    width: 100%;
    padding: 0 120px;
    box-sizing: border-box;
    margin-top: 36px;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    color: #333333;
  }
  .preview-other {
    width: 100%;
    padding: 0 120px;
    box-sizing: border-box;
    margin-top: 12px;
    font-size: 12px;
    font-weight: 400;
    color: #999999;
    .time-img {
      width: 16px;
      height: 16px;
    }
    .time {
      margin-left: 4px;
    }
    .download-img {
      width: 16px;
      height: 16px;
      margin-left: 24px;
    }
    .download {
      margin-left: 4px;
    }
  }
  .preview-content {
    width: 100%;
    padding: 0 120px;
    box-sizing: border-box;
    margin-top: 36px;
    margin-bottom: 64px;
    .left {
      width: 880px;
      height: min-content;
      background: #ffffff;
      box-shadow: 0px 16px 20px rgba(0, 0, 0, 0.15);
      line-height: 0;
      video {
        width: 100%;
        height: 100%;
      }
      img {
        width: 100%;
      }
      .no-data-container {
        width: 100%;
        height: 600px;
        img {
          width: 260px;
          height: 224px;
        }
      }
      .finish {
        width: 100%;
        height: 117px;
        border-top: 1px dashed #bebebe;
        img {
          width: 20px;
        }
        p {
          margin-bottom: 0;
          margin-left: 6px;
          font-size: 16px;
          font-weight: 400;
          color: #333333;
        }
      }
    }
    .right {
      width: 296px;
      .btn {
        width: 100%;
        height: 48px;
        button {
          height: 100%;
          font-size: 14px;
        }
        .upload {
          width: 188px;
        }
        .collection {
          width: 96px;
          height: 100%;
          background: #cfefdc;
          border: 1px solid #05ae44;
          border-radius: 2px;
          color: #05ae44;
          outline: none;
          cursor: pointer;
          img {
            width: 20px;
            margin-right: 2px;
          }
        }
      }
      .recommend {
        width: 100%;
        min-height: 525px;
        background: #ffffff;
        margin-top: 24px;
        padding: 24px 21px 0 12px;
        box-sizing: border-box;
        .title {
          font-size: 18px;
          color: #333333;
        }
        .item {
          width: 100%;
          padding: 12px 0px;
          box-sizing: border-box;
          border-bottom: 1px dashed #bebebe;
          cursor: pointer;
          img {
            width: 16px;
            height: 16px;
            margin-top: 2px;
          }
          p {
            width: 100%;
            // height: 38px;
            margin-left: 8px;
            margin-bottom: 0;
            font-size: 14px;
            line-height: 19px;
            color: #6a6a6a;
            overflow: hidden;
            -webkit-line-clamp: 2;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
          }
        }
        .item:last-child {
          border-bottom: none;
        }
        .item:hover p {
          color: @main-color;
        }
      }
    }
  }
}
</style>
